<template>
  <main class="enterprise-landing">
    <section class="section1">
      <div class="center">
        <h1 class="title"><span class="deco-font">BIGC Enterprise</span></h1>
        <h2 class="sub-title">
          ALL-in-one solution <br class="mo" />for IP∙Media Business
        </h2>
        <p class="desc">
          IP와 콘텐츠의 브랜드 홈, 라이브 솔루션 & AI MC, <br class="mo" />CMS,
          글로벌 커머스, 분석 솔루션까지 <br class="pc" />IP 수익화에
          <br class="mo" />포커스된 종합적인 Global No.1 Media Solution을
          <br class="mo" />
          제공합니다.
        </p>
      </div>
      <div class="center">
        <div class="type-area">
          <div>
            <div class="type-traits">
              <div class="type">Type A</div>
            </div>
            <h3 class="title">
              IP 미디어 비지니스를 위한 <br />솔루션 컨설팅 및 R&D
            </h3>
            <p class="desc">
              빅크의 핵심 솔루션과 인프라를 기본으로 IP 맞춤형 플랫폼
              <br class="pc" />을 개발, 고도화 합니다.
            </p>
          </div>
          <div>
            <div class="type-traits">
              <div class="type">Type B</div>
            </div>
            <h3 class="title">빅크 스튜디오 <br />엔터프라이즈 제휴</h3>
            <p class="desc">
              빅크 스튜디오를 기본으로 활용하여, 기업 또는
              <br class="mo" />브랜드, 채널의 브랜드 홈과 IP 수익화를 위한
              <br class="mo" />제반 기능을 제공합니다.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!--    the parters of bigc -->
    <section class="section2">
      <div class="center">
        <h2 class="title">빅크와 함께하는 파트너</h2>
        <div class="partner-area">
          <div class="partner-cj"></div>
          <div class="partner-mbc"></div>
          <div class="partner-lg"></div>
        </div>
      </div>
    </section>
    <!--    enterprise solution -->
    <section class="section3">
      <div class="center">
        <h3 class="sub-title">한눈에 보기</h3>
        <h2 class="title">엔터프라이즈 솔루션</h2>
        <div class="feature-wrapper">
          <div class="conference">
            <h4 class="title">BIG Conference</h4>
            <p class="desc">
              글로벌 컨퍼런스 및 대형 콘서트에<br />
              최적화된 라이브 스트리밍 솔루션
            </p>
          </div>
          <div class="chat">
            <h4 class="title">BIG Chat</h4>
            <p class="desc">
              슬로우, 프리징, AI 필터링까지<br />
              다양한 채팅 솔루션
            </p>
          </div>
          <div class="ai-mc">
            <h4 class="title">BIG AI MC</h4>
            <p class="desc">인공지능 보조MC의<br />라이브 진행 솔루션</p>
          </div>
          <div class="home">
            <h4 class="title">BIG Home</h4>
            <p class="desc">
              다양한 위젯 기능을 갖춘<br />
              홈 빌딩 솔루션
            </p>
          </div>
          <div class="vote">
            <h4 class="title">BIG Vote</h4>
            <p class="desc">
              글로벌 오디션 프로그램에 최적화된<br />
              투표 솔루션
            </p>
          </div>
          <div class="crm-analytics">
            <h4 class="title">BIG CRM & Analytics</h4>
            <p class="desc">
              글로벌 마케팅 및 팬 운영을 위한<br />
              데이터 분석 솔루션
            </p>
          </div>
          <div class="cms-commerce">
            <h4 class="title">BIG CMS & Commerce</h4>
            <p class="desc">
              IP 콘텐츠 및 굿즈 판매를 위한<br />
              글로벌 커머스 솔루션
            </p>
          </div>
          <div class="point">
            <h4 class="title">BIG Point</h4>
            <p class="desc">
              판매 부스팅을 위한<br />
              디지털 인센티브 솔루션
            </p>
          </div>
          <div class="community">
            <h4 class="title">BIG Community</h4>
            <p class="desc">
              판매 활성화를 위한<br />
              커뮤니티 지원
            </p>
          </div>
        </div>
      </div>
    </section>
    <!--    enterprise solution details-->
    <section class="section4">
      <div class="center">
        <div class="card-wrapper">
          <enterprise-solution
            :img-src="`${solutionImgPath}bigc_conference.${state.imageExtension}`"
            :img-src-mo="`${solutionImgPath}bigc_conference_m.${state.imageExtension}`"
          >
            <template #subTitle>
              <h3 class="sub-title">BIG Conference</h3>
            </template>
            <template #title>
              <h2 class="title">빅크 라이브 솔루션</h2>
            </template>
            <template #desc>
              <p class="desc">
                글로벌 컨퍼런스 및 방송형 라이브 <br class="mo" />스트리밍에
                차별화된 기술이 적용 되어 있습니다.
              </p>
            </template>
            <template #list>
              <ul class="main-card-list">
                <li>지연율 약 0.5초로 유튜브 라이브 대비 약 20배 지연 개선</li>
                <li>노이즈 제거, 개인 콘트롤 등 오디오 제어 기능</li>
                <li>최대 해상도 1080p (FHD)</li>
                <li>약 10만명 동시 시청가능</li>
              </ul>
            </template>
          </enterprise-solution>
          <div>
            <ul class="sub-card-list">
              <li
                class="card"
                :class="{ active: state.conferenceFeature === 1 }"
                @mouseover="actions.toggleConferenceFeature(1)"
                @mouseleave="actions.toggleConferenceFeature(0)"
                @click="actions.setConferenceFeature(1)"
              >
                <div class="card-body">
                  <h4 class="title">원클릭 이벤트 생성</h4>
                  <p class="desc">
                    타입별 실시간 라이브 이벤트에 대한 고급 설정 제공, 실시간
                    소통, 티켓 결제 기능 지원
                  </p>
                  <div class="plus-btn"></div>
                </div>
                <ul class="sub-desc">
                  <li>다양한 옵션의 멀티 티켓 등록 및 글로벌 결제 지원</li>
                  <li>라이브 클럽 등 세션 복제 후 재사용∙판매 가능</li>
                  <li>
                    스트리밍 라이브 콘서트, 대형 컨퍼런스, 1:N 세미나, 1:1
                    멘토링 등 설정
                  </li>
                  <li>
                    별도 SW 설치 없이 PC, 모바일 웹을 통해 이벤트 생성 가능
                  </li>
                  <li>라이브 이벤트 생성 및 운영에 소요되는 단계 최소화</li>
                </ul>
                <div
                  class="card-bg pc"
                  :style="`background-image: url(${solutionImgPath}bigc_conference_event.${state.imageExtension})`"
                ></div>
                <div
                  class="card-bg mo"
                  :style="`background-image: url(${solutionImgPath}bigc_conference_event_m.${state.imageExtension})`"
                ></div>
              </li>
              <li
                class="card"
                :class="{ active: state.conferenceFeature === 2 }"
                @mouseover="actions.toggleConferenceFeature(2)"
                @mouseleave="actions.toggleConferenceFeature(0)"
                @click="actions.setConferenceFeature(2)"
              >
                <div class="card-body">
                  <h4 class="title">글로벌 라이브 대응</h4>
                  <p class="desc">
                    한국어 - 글로벌 언어 자동 번역에 특화된 글로벌
                    <br class="mo" />자막 생성 기능 제공
                  </p>
                  <ul class="sub-desc">
                    <li>
                      휴먼 오퍼레이터 없이 음성 인식으로 글로벌 자막 생성
                      (한국어 유행어 등 포함)
                    </li>
                    <li>
                      현재 중문, 영문, 일문, 인도네시아어, 베트남어 5개국어
                      지원, 최대 스페인어 등 12개국어 지원, 한국어에 특화됨
                    </li>
                    <li>
                      게스트 유저를 인식해 해당 국가에 따라 자막 언어를 번역
                      제공
                    </li>
                    <li>글로벌 라이브 생중계 및 송출 지원</li>
                  </ul>
                  <div class="plus-btn"></div>
                </div>
                <div
                  class="card-bg pc"
                  :style="`background-image: url(${solutionImgPath}bigc_conference_global.${state.imageExtension})`"
                ></div>
                <div
                  class="card-bg mo"
                  :style="`background-image: url(${solutionImgPath}bigc_conference_global_m.${state.imageExtension})`"
                ></div>
              </li>
              <li
                class="card"
                :class="{ active: state.conferenceFeature === 3 }"
                @mouseover="actions.toggleConferenceFeature(3)"
                @mouseleave="actions.toggleConferenceFeature(0)"
                @click="actions.setConferenceFeature(3)"
              >
                <div class="card-body">
                  <h4 class="title">대기룸 및 CRM</h4>
                  <p class="desc">
                    라이브 입장부터 진행, 사후 분석까지 올<br class="pc" />인원
                    경험
                  </p>
                  <div class="plus-btn"></div>
                </div>
                <ul class="sub-desc">
                  <li>
                    대기룸에서 추가 공유 바이럴, 안내, 사전 시스템 체크 기능
                    제공, 입장권 구매자에 한해 라이브 입장 제공
                  </li>
                  <li>티켓 구매자 대상으로 진행하는 이벤트 전중후 알림 기능</li>
                </ul>
                <div
                  class="card-bg pc"
                  :style="`background-image: url(${solutionImgPath}bigc_conference_room.${state.imageExtension})`"
                ></div>
                <div
                  class="card-bg mo"
                  :style="`background-image: url(${solutionImgPath}bigc_conference_room_m.${state.imageExtension})`"
                ></div>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-wrapper">
          <enterprise-solution
            :img-src="`${solutionImgPath}bigc_chat.${state.imageExtension}`"
            :img-src-mo="`${solutionImgPath}bigc_chat_m.${state.imageExtension}`"
            img-direction="left"
          >
            <template #subTitle>
              <h3 class="sub-title">BIG Chat</h3>
            </template>
            <template #title>
              <h2 class="title">슬로우 챗∙챗 프리징</h2>
            </template>
            <template #desc>
              <p class="desc">
                라이브에 실시간 이용자가 다수 몰릴 경우에도 호스트-게스트간 채팅
                인터렉션 사용성을 높입니다.
              </p>
            </template>
            <template #list>
              <ul class="main-card-list">
                <li>
                  채팅 제한 슬로우 챗 기능<br />1~N초 채팅 작성 제한 구간 설정
                </li>
              </ul>
            </template>
          </enterprise-solution>
          <!--          <div>-->
          <!--            <ul class="sub-card-list">-->
          <!--              <li class="card card-horizontal">-->
          <!--                <div class="card-body">-->
          <!--                  <h4 class="title">AI 필터링</h4>-->
          <!--                  <p class="desc">문구 없음</p>-->
          <!--                  <div class="plus-btn"></div>-->
          <!--                </div>-->
          <!--                <div class="img"></div>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </div>-->
        </div>
        <div class="card-wrapper">
          <enterprise-solution
            :img-src="`${solutionImgPath}bigc_ai_mc.${state.imageExtension}`"
            :img-src-mo="`${solutionImgPath}bigc_ai_mc_m.${state.imageExtension}`"
          >
            <template #subTitle>
              <h3 class="sub-title">BIG AI MC</h3>
            </template>
            <template #title>
              <h2 class="title">Live Virtual MC</h2>
            </template>
            <template #desc>
              <p class="desc">
                인공지능 MC가 보조 진행자가 되어 <br class="mo" />테마 세션
                기획∙콘텐츠∙게스트 운영∙분석까지 모두 해결합니다.
              </p>
            </template>
            <template #list>
              <ul class="main-card-list">
                <li>예능, 팬미팅 등 테마별 라이브 콘텐츠 제공</li>
                <li>라이브 시퀀스 별 큐시트 제공, 편집 가능</li>
                <li>투표 및 밸런스 게임 등 라이브 중 인터렉션 제공</li>
                <li>
                  인공지능 게스트 및 리액션 : 게스트 호응 상세 설정 및 인터렉션
                  유도 기능
                </li>
              </ul>
            </template>
          </enterprise-solution>
          <!--          <div>-->
          <!--            <ul class="sub-card-list">-->
          <!--              <li class="card card-horizontal">-->
          <!--                <div class="card-body">-->
          <!--                  <h4 class="title">인공지능 게스트 및 리액션</h4>-->
          <!--                  <p class="desc">문구 없음</p>-->
          <!--                  <div class="plus-btn"></div>-->
          <!--                </div>-->
          <!--                <ul class="sub-desc">-->
          <!--                  <li>게스트 호응 및 인터렉션 유도 기능</li>-->
          <!--                </ul>-->
          <!--                <div class="card-bg"></div>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </div>-->
        </div>
        <div class="card-wrapper">
          <enterprise-solution
            :img-src="`${solutionImgPath}bigc_home.${state.imageExtension}`"
            :img-src-mo="`${solutionImgPath}bigc_home_m.${state.imageExtension}`"
            img-direction="left"
          >
            <template #subTitle>
              <h3 class="sub-title">BIG Home</h3>
            </template>
            <template #title>
              <h2 class="title">브랜드∙IP 쇼룸</h2>
            </template>
            <template #desc>
              <p class="desc">
                IP∙콘텐츠 브랜드 홈을 쇼룸으로, 프리미엄 경험과 함께 다양한
                콘텐츠 큐레이션이 가능합니다.
              </p>
            </template>
            <template #list>
              <ul class="main-card-list">
                <li>기본 IP 브랜드 홈 - 소개 및 랜딩 페이지 및 커뮤니티</li>
                <li>유료 콘텐츠 및 이벤트 홈화면 큐레이션</li>
                <li>관련 SNS 모아보기 기능</li>
                <li>
                  링크인 바이오 : 멀티 SNS 채널 링크 및 부가 상품 링크 연결
                  (링크트리)
                </li>
              </ul>
            </template>
          </enterprise-solution>
          <div>
            <ul class="sub-card-list">
              <li
                class="card card-horizontal"
                :class="{ active: state.conferenceFeature === 4 }"
                @mouseover="actions.toggleConferenceFeature(4)"
                @mouseleave="actions.toggleConferenceFeature(0)"
                @click="actions.setConferenceFeature(4)"
              >
                <div class="card-body">
                  <h4 class="title">모바일 홈 위젯 UX</h4>
                  <p class="desc">
                    편집이 편리한 위젯 UX로 모든 콘텐츠를 효과적으로 큐레이션
                  </p>
                  <div class="plus-btn"></div>
                </div>
                <ul class="sub-desc">
                  <li>위젯 형태로 정렬 및 구성 자유롭게 편집 가능</li>
                  <li>모바일 커뮤니티, SNS 피드 등 높은 접근성</li>
                </ul>
                <div
                  class="card-bg pc"
                  :style="`background-image: url(${solutionImgPath}bigc_home_mobile.${state.imageExtension})`"
                ></div>
                <div
                  class="card-bg mo"
                  :style="`background-image: url(${solutionImgPath}bigc_home_mobile_m.${state.imageExtension})`"
                ></div>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-wrapper">
          <enterprise-solution
            :img-src="`${solutionImgPath}bigc_vote.${state.imageExtension}`"
            :img-src-mo="`${solutionImgPath}bigc_vote_m.${state.imageExtension}`"
          >
            <template #subTitle>
              <h3 class="sub-title">BIG Vote</h3>
            </template>
            <template #title>
              <h2 class="title">투표</h2>
            </template>
            <template #desc>
              <p class="desc">
                수요조사 및 오디션 프로그램에 활용할 수 있습니다.
              </p>
            </template>
            <template #list>
              <ul class="main-card-list">
                <li>투표기능 위젯</li>
                <li>투표 사후 데이터 분석</li>
                <li>투표 정확도 검증 QA</li>
              </ul>
            </template>
          </enterprise-solution>
        </div>
        <div class="card-wrapper">
          <enterprise-solution
            :img-src="`${solutionImgPath}bigc_crm.${state.imageExtension}`"
            :img-src-mo="`${solutionImgPath}bigc_crm_m.${state.imageExtension}`"
            img-direction="left"
          >
            <template #subTitle>
              <h3 class="sub-title">BIG CRM & Analytics</h3>
            </template>
            <template #title>
              <h2 class="title">데이터 대시보드</h2>
            </template>
            <template #desc>
              <p class="desc">
                가상 이벤트 및 IP 판매 수입은 대시보드로 제공되며 구독자, 팬
                분석을 위한 CRM툴을 제공합니다.
              </p>
            </template>
            <template #list>
              <ul class="main-card-list">
                <li>콘텐츠 수익∙팬∙채널 대시보드</li>
                <li>SNS 멀티 채널 분석 도구 제공</li>
                <li>구독 팬 매니지먼트 및 팬 / 팔로워 분석</li>
              </ul>
            </template>
          </enterprise-solution>
          <div>
            <ul class="sub-card-list">
              <li
                class="card card-horizontal"
                :class="{ active: state.conferenceFeature === 5 }"
                @mouseover="actions.toggleConferenceFeature(5)"
                @mouseleave="actions.toggleConferenceFeature(0)"
                @click="actions.setConferenceFeature(5)"
              >
                <div class="card-body">
                  <h4 class="title">Global Audience Optimization</h4>
                  <p class="desc">
                    글로벌 오디언스 대상의 이벤트 및 마케팅 최적화도 효율적으로
                    진행
                  </p>
                  <ul class="sub-desc">
                    <li>
                      글로벌 이벤트 사후 유입 국가 및 전환 등 분석 데이터 제공
                    </li>
                  </ul>
                  <div class="plus-btn"></div>
                </div>
                <div
                  class="card-bg pc"
                  :style="`background-image: url(${solutionImgPath}bigc_crm_global.${state.imageExtension})`"
                ></div>
                <div
                  class="card-bg mo"
                  :style="`background-image: url(${solutionImgPath}bigc_crm_global_m.${state.imageExtension})`"
                ></div>
              </li>
              <!--              <li class="card">-->
              <!--                <div class="card-body">-->
              <!--                  <h4 class="title">CRM</h4>-->
              <!--                  <p class="desc">요약글 필요합니다.</p>-->
              <!--                  <ul class="sub-desc">-->
              <!--                    <li>-->
              <!--                      휴먼 오퍼레이터 없이 음성 인식으로 글로벌 자막 생성-->
              <!--                      (한국어 유행어 등 포함)-->
              <!--                    </li>-->
              <!--                    <li>-->
              <!--                      현재 중문, 영문, 일문, 인도네시아어, 베트남어 5개국어-->
              <!--                      지원, 최대 스페인어 등 12개국어 지원, 한국어에 특화됨-->
              <!--                    </li>-->
              <!--                    <li>-->
              <!--                      게스트 유저를 인식해 해당 국가에 따라 자막 언어를 번역-->
              <!--                      제공-->
              <!--                    </li>-->
              <!--                    <li>글로벌 라이브 생중계 및 송출 지원</li>-->
              <!--                  </ul>-->
              <!--                  <div class="plus-btn"></div>-->
              <!--                </div>-->
              <!--                <div class="card-bg"></div>-->
              <!--              </li>-->
            </ul>
          </div>
        </div>
        <div class="card-wrapper">
          <enterprise-solution
            :img-src="`${solutionImgPath}bigc_cms.${state.imageExtension}`"
            :img-src-mo="`${solutionImgPath}bigc_cms_m.${state.imageExtension}`"
            :is-border-radius="false"
          >
            <template #subTitle>
              <h3 class="sub-title">BIG CMS & Commerce</h3>
            </template>
            <template #title>
              <h2 class="title">IP∙Contents Commerce</h2>
            </template>
            <template #desc>
              <p class="desc">
                다양한 타입의 무형 유료 콘텐츠를 등록하여 편리하게 운영, 관리,
                판매할 수 있습니다.(VOD, AOD, 전자책...)
              </p>
            </template>
            <template #list>
              <ul class="main-card-list">
                <li>유료 콘텐츠(영상, 아티클, 오디오 등) 등록 판매</li>
                <li>상품별 상세 페이지 CMS, 가격 설정 및 판매 관리</li>
              </ul>
            </template>
          </enterprise-solution>
          <div>
            <ul class="sub-card-list">
              <li
                class="card"
                :class="{ active: state.conferenceFeature === 6 }"
                @mouseover="actions.toggleConferenceFeature(6)"
                @mouseleave="actions.toggleConferenceFeature(0)"
                @click="actions.setConferenceFeature(6)"
              >
                <div class="card-body">
                  <h4 class="title">Global Commerce</h4>
                  <p class="desc">
                    오피셜 후속 컨텐츠의 기획부터 유통까지 간편하<br
                      class="mo"
                    />게 관리 가능
                  </p>
                  <ul class="sub-desc">
                    <li>
                      해외 간편 결제 지원<br />
                      (페이팔 및 Master, Visa 신용 카드 결제 제공)
                    </li>
                    <li>글로벌 엔드 유저 영어 CS 대응</li>
                  </ul>
                  <div class="plus-btn"></div>
                </div>
                <div
                  class="card-bg pc"
                  :style="`background-image: url(${solutionImgPath}bigc_cms_global.${state.imageExtension})`"
                ></div>
                <div
                  class="card-bg mo"
                  :style="`background-image: url(${solutionImgPath}bigc_cms_global_m.${state.imageExtension})`"
                ></div>
              </li>
              <li
                class="card"
                :class="{ active: state.conferenceFeature === 7 }"
                @mouseover="actions.toggleConferenceFeature(7)"
                @mouseleave="actions.toggleConferenceFeature(0)"
                @click="actions.setConferenceFeature(7)"
              >
                <div class="card-body">
                  <h4 class="title">빅크 테마 라이브러리</h4>
                  <p class="desc">
                    시중에 판매되는 유료 콘텐츠 데이터를 분석, 수익화가 유리한
                    테마 콘텐츠, 권장 판매가 제공
                  </p>
                  <ul class="sub-desc">
                    <li>
                      유료 판매 3만종 데이터 분석 기반 콘텐츠 유료화에 포커스,
                      콘텐츠 샘플 라이브러리 제공
                    </li>
                    <li>테마∙주제 및 가격대 매칭 추천(전담 매니저)</li>
                  </ul>
                  <div class="plus-btn"></div>
                </div>
                <div
                  class="card-bg pc"
                  :style="`background-image: url(${solutionImgPath}bigc_cms_theme.${state.imageExtension})`"
                ></div>
                <div
                  class="card-bg mo"
                  :style="`background-image: url(${solutionImgPath}bigc_cms_theme_m.${state.imageExtension})`"
                ></div>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-wrapper">
          <enterprise-solution
            :img-src="`${solutionImgPath}bigc_point.${state.imageExtension}`"
            :img-src-mo="`${solutionImgPath}bigc_point_m.${state.imageExtension}`"
            img-direction="left"
          >
            <template #subTitle>
              <h3 class="sub-title">BIG Point</h3>
            </template>
            <template #title>
              <h2 class="title">디지털 인센티브</h2>
            </template>
            <template #desc>
              <p class="desc">
                유료 구독자 및 팬덤 스케일업을 위한 채널별 디지털 인센티브를
                제공하여 리텐션을 유도합니다.
              </p>
            </template>
            <template #list>
              <ul class="main-card-list">
                <li>크리에이터 스페이스 별 정기결제 설정 및 알림</li>
                <li>결제 발생 시 크리에이터 전용 포인트 적립</li>
                <li>
                  적립∙소멸에 대한 푸시 제공<br />
                  (In-app /DM/kakao Push 등)
                </li>
                <li>고객 추가 확보 퍼포먼스 마케팅 옵션</li>
              </ul>
            </template>
          </enterprise-solution>
        </div>
        <div class="card-wrapper">
          <enterprise-solution
            :img-src="`${solutionImgPath}bigc_community.${state.imageExtension}`"
            :img-src-mo="`${solutionImgPath}bigc_community_m.${state.imageExtension}`"
          >
            <template #subTitle>
              <h3 class="sub-title">BIG Community</h3>
            </template>
            <template #title>
              <h2 class="title">
                IP∙Brand <br class="pc" />Community <br class="pc" />Support
              </h2>
            </template>
            <template #desc>
              <p class="desc">
                오디션 및 인기투표 프로그램 방송 <br />IP의 커뮤니티 강화에도
                활용됩니다.
              </p>
            </template>
            <template #list>
              <ul class="main-card-list">
                <li>
                  참여 크리에이터들의 관련 포스팅 유도 및 바이럴 강화로 방송
                  프로그램 사전 마케팅
                </li>
                <li>참여 팬에 대한 전중후 이벤트 안내, 가이드 운영</li>
                <li>진성팬, 아티스트 보호를 위한 악성 댓글 필터링</li>
                <li>이벤트 세션 별 별도 라운지 공간 제공</li>
              </ul>
            </template>
          </enterprise-solution>
        </div>
      </div>
    </section>
    <!--    bigc references-->
    <section class="section5">
      <div class="center">
        <h3 class="sub-title">Case Highlights</h3>
        <h2 class="title">주요 레퍼런스</h2>
        <div class="reference-wrapper">
          <div
            class="reference"
            :style="`background-image: url(${state.selectedReference.imgSrc}.${state.imageExtension})`"
          >
            <h4 class="title">{{ state.selectedReference.title }}</h4>
            <p
              class="desc pc"
              v-html="state.selectedReference.description.pc"
            ></p>
            <p
              class="desc mo"
              v-html="state.selectedReference.description.mo"
            ></p>

            <a
              v-if="state.selectedReference.name === 'mama'"
              target="_blank"
              :href="state.selectedReference.url"
              >사이트 바로가기</a
            >
          </div>
          <div class="reference-list">
            <div
              :style="`background-image: url(${references[0].imgSrc}.${state.imageExtension})`"
              :class="{
                active: state.selectedReference.name === references[0].name,
              }"
              @click="actions.selectReference(0)"
            ></div>
            <div
              :style="`background-image: url(${references[1].imgSrc}.${state.imageExtension})`"
              :class="{
                active: state.selectedReference.name === references[1].name,
              }"
              @click="actions.selectReference(1)"
            ></div>
            <div
              :style="`background-image: url(${references[2].imgSrc}.${state.imageExtension})`"
              :class="{
                active: state.selectedReference.name === references[2].name,
              }"
              @click="actions.selectReference(2)"
            ></div>
            <div
              :style="`background-image: url(${references[3].imgSrc}.${state.imageExtension})`"
              :class="{
                active: state.selectedReference.name === references[3].name,
              }"
              @click="actions.selectReference(3)"
            ></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section10">
      <div class="center">
        <h2 class="title">이 모든 것, 빅크와 함께 <br />지금 시작해보세요</h2>
        <div class="btn-wrapper">
          <a
            class="btn-partner"
            target="_blank"
            href="https://tally.so/r/3j06Yn"
          >
            기업 파트너 문의 <span class="arrow-icon"></span
          ></a>
          <a
            class="btn-creator"
            target="_blank"
            href="https://tally.so/r/wLyyjn"
          >
            개인 크리에이터 문의 <span class="arrow-icon"></span
          ></a>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {
  getCurrentInstance,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
} from "vue";
import { useHead } from "@vueuse/head";
import EnterpriseSolution from "./EnterpriseSolution/EnterpriseSolution";
import { webpIsSupported } from "@/helper/landing";

export default {
  name: "EnterpriseLanding",
  components: { EnterpriseSolution },
  setup() {
    useHead({
      title: "빅크 스튜디오 | 크리에이터가 되는 가장 빠른 방법",
      meta: [
        {
          name: "description",
          content:
            "1,000만 조회를 넘어 1,000명의 서포터즈를 만드세요. 빅크 스튜디오는 크리에이터를 위한 구독 페이지 및 온라인 라이브 솔루션을 제공합니다.",
        },
        {
          name: "keywords",
          content: "빅크,셀럽,크리에이터,작가,뮤지션,전문가",
        },
        // OGP
        { property: "og:site_name", content: "BIGC - site name" },
        { property: "og:type", content: "website" },
        {
          property: "og:title",
          content: "빅크 스튜디오 | 크리에이터가 되는 가장 빠른 방법",
        },
        {
          property: "og:description",
          content:
            "1,000만 조회를 넘어 1,000명의 서포터즈를 만드세요. 빅크 스튜디오는 크리에이터를 위한 구독 페이지 및 온라인 라이브 솔루션을 제공합니다.",
        },
        {
          property: "og:image",
          content:
            "https://www.bigc.studio/assets/web_image/seo/bigc-studio.png",
        },
        { property: "og:url", content: "https://www.bigc.studio/about" },

        {
          name: "twitter:title",
          content: "빅크 스튜디오 | 크리에이터가 되는 가장 빠른 방법",
        },
        {
          name: "twitter:description",
          content:
            "1,000만 조회를 넘어 1,000명의 서포터즈를 만드세요. 빅크 스튜디오는 크리에이터를 위한 구독 페이지 및 온라인 라이브 솔루션을 제공합니다.",
        },
        {
          name: "twitter:image",
          content:
            "https://www.bigc.studio/assets/web_image/seo/bigc-studio.png",
        },
        // QQ
        { itemprop: "name", content: "Bigc studio" },
        {
          itemprop: "description",
          content:
            "1,000만 조회를 넘어 1,000명의 서포터즈를 만드세요. 빅크 스튜디오는 크리에이터를 위한 구독 페이지 및 온라인 라이브 솔루션을 제공합니다.",
        },
        {
          itemprop: "image",
          content:
            "https://www.bigc.studio/assets/web_image/seo/bigc-studio.png",
        },
      ],
    });

    const { proxy } = getCurrentInstance();

    const state = reactive({
      selectedReference: {
        name: "mama",
        title: "CJ ENM MAMA 2022 온라인 시스템 개발",
        description: {
          pc: `"MAMA"는 한국 및 아시아 주요 채널과 온라인 플랫폼을 통해 전세계 200여 개 국가 및 <br/>지역에서 생중계되는 글로벌 음악 시상식으로 국적, 인종, 세대를 뛰어넘어 전세계의 <br/>음악 팬들이 함께 소통할 수 있는 글로벌 음악 축제입니다.`,
          mo: `"MAMA"는 한국 및 아시아 주요 채널과 온라인 플랫폼을 통해 <br/>전세계 200여 개 국가 및 지역에서 생중계되는 글로벌 음악<br/>시상식으로 국적, 인종, 세대를 뛰어넘어 전세계의 음악 팬들이 <br/>함께 소통할 수 있는 글로벌 음악 축제입니다.`,
        },
        url: "https://mama.mnetplus.world",
        imgSrc: "/assets/web_image/enterprise/reference/mama",
      },
      imageExtension: "webp",

      conferenceFeature: 0,
    });

    const emptyImg = proxy.$const.blankImage;

    const references = [
      {
        name: "mama",
        title: "CJ ENM MAMA 2022 온라인 시스템 개발",
        description: {
          pc: `"MAMA"는 한국 및 아시아 주요 채널과 온라인 플랫폼을 통해 전세계 200여 개 국가 및 <br/>지역에서 생중계되는 글로벌 음악 시상식으로 국적, 인종, 세대를 뛰어넘어 전세계의<br/>음악 팬들이 함께 소통할 수 있는 글로벌 음악 축제입니다.`,
          mo: `"MAMA"는 한국 및 아시아 주요 채널과 온라인 플랫폼을 통해 <br/>전세계 200여 개 국가 및 지역에서 생중계되는 글로벌 음악<br/>시상식으로 국적, 인종, 세대를 뛰어넘어 전세계의 음악 팬들이 <br/>함께 소통할 수 있는 글로벌 음악 축제입니다.`,
        },
        url: "https://mama.mnetplus.world",
        imgSrc: "/assets/web_image/enterprise/reference/mama",
      },
      {
        name: "bloodGame",
        title: "피의게임2 온라인 플랫폼 개발",
        description: {
          pc: `MBC와 OTT 플랫폼 Wavve(웨이브)가 공동 제작한 '피의 게임'은 플레이어들이 완성도 높은 <br/>게임룰 속에서 연합과 배신을 오가며 최종 상금을 차지하기 위해 경쟁을 펼치는 <br/>리얼리티 서바이벌 예능입니다.`,
          mo: `MBC와 OTT 플랫폼 Wavve(웨이브)가 공동 제작한 '피의 게<br/>임'은 플레이어들이 완성도 높은 게임룰 속에서 연합과 배신을<br/>오가며 최종 상금을 차지하기 위해 경쟁을 펼치는<br/>리얼리티 서바이벌 예능입니다.`,
        },
        url: "https://www.bigc.im/bloodygame",
        imgSrc: "/assets/web_image/enterprise/reference/blood_game",
      },
      {
        name: "golfluencer",
        title: "MBC+ 골플루언서 온라인 플랫폼 개발",
        description: {
          pc: `‘골플루언서’는 골프 + 인플루언서로 총상금 3000만원을 걸고 모델, 치어리더, 가수, 개그우먼, <br/>미스코리아, 아나운서, 쇼호스트 등 각 분야를 대표하는 72명의 골플루언서들이 4인 1팀이 되어 <br/>골프 챌린지에 도전하는 스포츠 예능입니다.`,
          mo: `‘골플루언서’는 골프 + 인플루언서로 총상금 3000만원을 걸고 <br/>모델, 치어리더, 가수, 개그우먼, 미스코리아, 아나운서, 쇼호스트 <br/>등 각 분야를 대표하는 72명의 골플루언서들이 4인 1팀이 되어 <br/>골프 챌린지에 도전하는 스포츠 예능입니다.`,
        },
        url: "https://www.bigc.im/golfluencer",
        imgSrc: "/assets/web_image/enterprise/reference/golfluencer",
      },
      {
        name: "lgUPlus",
        title: "LG U+ 교양있高 온라인 플랫폼",
        description: {
          pc: `LG U+ ‘STUDIO X+U’가 선보이는 새 오리지널 콘텐츠 &lt;교양있고&gt;는 K-pop 아이돌의 교양 수<br/>준을 진단하고 그에 맞는 지식 케어를 제공하는 콘셉트의 예능 콘텐츠 입니다. 브랜드 홈에서는 <br/>다양한 K-아이돌, 비하인드 콘텐츠를 즐길 수 있습니다.`,
          mo: `LG U+ ‘STUDIO X+U’가 선보이는 새 오리지널 콘텐츠 &lt;교양<br/>있고&gt;는 K-pop 아이돌의 교양 수준을 진단하고 그에 맞는 지식 <br/>케어를 제공하는 콘셉트의 예능 콘텐츠 입니다. 브랜드 홈에서는 <br/>다양한 K-아이돌, 비하인드 콘텐츠를 즐길 수 있습니다.`,
        },
        url: "",
        imgSrc: "/assets/web_image/enterprise/reference/lg_u_plus",
      },
    ];

    onBeforeMount(() => {
      state.imageExtension = webpIsSupported() ? "webp" : "jpg";
    });

    onMounted(() => {
      initCss();
    });

    onBeforeUnmount(() => {
      resetCss();
    });

    const initCss = () => {
      const html = document.getElementsByTagName("html")[0].style;
      const app = document.getElementById("app").style;
      const body = document.body.style;
      html.position = "static";
      html.overflowY = "visible";
      body.position = "static";
      body.overflow = "visible";
      body.height = "auto";
      app.overflowX = "visible";
      app.overflowY = "visible";
    };

    const resetCss = () => {
      const html = document.getElementsByTagName("html")[0].style;
      const app = document.getElementById("app").style;
      const body = document.body.style;
      html.position = "fixed";
      html.overflowY = "hidden";
      body.position = "fixed";
      body.overflow = "hidden";
      body.height = "100%";
      app.overflowY = "scroll";
    };

    const solutionImgPath = "/assets/web_image/enterprise/solution/";

    const actions = {
      selectReference: (index) => {
        state.selectedReference = references[index];
      },
      toggleConferenceFeature: (value) => {
        if (window.innerWidth < 1090) {
          return;
        }
        state.conferenceFeature = value;
      },
      setConferenceFeature: (value) => {
        if (window.innerWidth >= 1090) {
          return;
        }
        if (state.conferenceFeature === value) {
          state.conferenceFeature = 0;
        } else {
          state.conferenceFeature = value;
        }
      },
    };

    return { state, actions, emptyImg, references, solutionImgPath };
  },
};
</script>

<style scoped src="./style.css"></style>

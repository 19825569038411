<template>
  <div class="card" :class="`card-${imgDirection}`">
    <div class="card-text">
      <slot name="subTitle"> </slot>
      <slot name="title"></slot>
      <slot name="desc"></slot>

      <!--      <h3 class="headline" v-html="subTitle"></h3>-->
      <!--      <h2 class="title" v-html="title"></h2>-->
      <!--      <p class="desc pc" v-html="desc"></p>-->
      <!--      <p class="desc mo" v-html="descMo"></p>-->
      <div
        class="card-img mo"
        :class="{ 'border-radius': isBorderRadius }"
        :style="`background-image: url(${imgSrcMo});`"
      ></div>
      <slot name="list"></slot>
    </div>
    <div
      class="card-img pc"
      :class="{ 'border-radius': isBorderRadius }"
      :style="`background-image: url(${imgSrc});`"
    ></div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "EnterpriseSolution",
  props: {
    imgSrc: {
      type: String,
      default: "",
    },
    imgSrcMo: {
      type: String,
      default: "",
    },
    imgDirection: {
      type: String,
      default: "right",
    },
    isBorderRadius: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const state = reactive({});

    return { state };
  },
};
</script>

<style scoped>
.card {
  /*height: 500px;*/
  line-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 1090px) {
  .card {
    height: fit-content;
    min-height: 500px;
    line-height: 0;
    flex-direction: row;
    gap: 60px;
  }

  .card-text {
    flex-grow: 1;
    max-width: 370px;
  }
}

.card-left {
  flex-direction: column;
}

@media (min-width: 1090px) {
  .card-left {
    flex-direction: row-reverse;
  }
}

.card-img {
  width: 320px;
  height: 256px;
  margin-bottom: 36px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0;
  overflow: hidden;
}

.border-radius {
  border-radius: 8px;
}

@media (min-width: 1090px) {
  .card-img {
    width: unset;
    min-width: 630px;
    flex-basis: 630px;
    height: unset;
    margin-bottom: 0;
    align-self: stretch;
  }

  .border-radius {
    border-radius: 12px;
  }
}

.pc {
  display: none;
}

.mo {
  display: block;
}
@media (min-width: 1090px) {
  .pc {
    display: block;
  }
  .mo {
    display: none;
  }
}
</style>
